@import "~tailwindcss/base";
@import "~tailwindcss/components";
@import "~tailwindcss/utilities";

html {
  scroll-behavior: smooth;
}

body {
  font-family: 'rocGrotesk-regular', sans-serif;
  color: #142837;
}

@font-face {
  font-family: "rocGrotesk-light";
  src: url("./assets/fonts/rocGrotesk/RocGrotesk-Light.otf");
  font-display: swap;
}

@font-face {
  font-family: "rocGrotesk-regular";
  src: url("./assets/fonts/rocGrotesk/RocGrotesk-Regular.otf");
  font-display: swap;
}

@font-face {
  font-family: "rocGrotesk-medium";
  src: url("./assets/fonts/rocGrotesk/RocGrotesk-Medium.otf");
  font-display: swap;
}

@font-face {
  font-family: "rocGrotesk-bold";
  src: url("./assets/fonts/rocGrotesk/RocGrotesk-Bold.otf");
  font-display: swap;
}

@font-face {
  font-family: "flame";
  src: url("./assets/fonts/Flame\ Regular.ttf");
  font-display: swap;
}

@font-face {
  font-family: "inter-display";
  src: url("./assets/fonts/InterDisplay-SemiBold.otf");
  font-display: swap;
}

@font-face {
  font-family: "instrumentserif";
  src: url("./assets/fonts/Instrumentserif-italic.ttf");
  font-display: swap;
}
input[type='password'] {
  font-family: Verdana !important;
  font-size: 14px;
  letter-spacing: 0.12em;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px white inset !important;
  height: fit-content;
  margin-bottom: -4px;
  font-size: 14px;
  font-family: 'rocGrotesk-regular', sans-serif;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.floating-label {
  position: absolute;
  pointer-events: none;
  left: 12px;
  top: 15px;
  transition: 0.2s ease all;
}

.textAreafloating-label {
  position: absolute;
  pointer-events: none;
  left: 12px;
  top: 10px;
  transition: 0.2s ease all;
}

.textAreaInput:focus~.textAreafloating-label,
/* .textAreaInput:valid ~ .textAreafloating-label, */
.textAreaInput:not([value=""])+.textAreafloating-label,
.textAreaInput:focus:required~.textAreafloating-label,
.textAreaInput:focus:optional~.textAreafloating-label,
.textAreaInput:-webkit-autofill~.textAreafloating-label,
.textAreaInput:-webkit-autofill:hover~.textAreafloating-label,
.textAreaInput:-webkit-autofill:focus~.textAreafloating-label,
.textAreaInput:-webkit-autofill:active~.textAreafloating-label {
  top: 3px;
  bottom: 10px;
  left: 12px;
  font-size: 9px;
  opacity: 1;
}

input[type=date]~.floating-label,
.inputText:focus~.floating-label,
.inputText:not([value=""])~.floating-label,
.inputText:focus:required~.floating-label,
.inputText:focus:optional~.floating-label,
.inputText:-webkit-autofill~.floating-label,
.inputText:-webkit-autofill:hover~.floating-label,
.inputText:-webkit-autofill:focus~.floating-label,
.inputText:-webkit-autofill:active~.floating-label {
  top: 3px;
  bottom: 10px;
  left: 12px;
  font-size: 8px;
  opacity: 1;
}

input[type=date]~.floating-label .required,
.inputText:focus~.floating-label .required,
.inputText:not([value=""])~.floating-label .required,
.inputText:focus:required~.floating-label,
.inputText:focus:optional~.floating-label,
.inputText:-webkit-autofill~.floating-label .required,
.inputText:-webkit-autofill:hover~.floating-label .required,
.inputText:-webkit-autofill:focus~.floating-label .required,
.inputText:-webkit-autofill:active~.floating-label .required {
  font-size: 8px;
}

.floating-label.nav-search {
  position: absolute;
  pointer-events: none;
  left: 44px;
  top: 8px;
  transition: 0.2s ease all;
}

.inputText:focus~.floating-label.nav-search,
.inputText:not([value=""])~.floating-label.nav-search,
.inputText:focus:required~.floating-label.nav-search,
.inputText:focus:optional~.floating-label.nav-search,
.inputText:-webkit-autofill~.floating-label.nav-search,
.inputText:-webkit-autofill:hover~.floating-label.nav-search,
.inputText:-webkit-autofill:focus~.floating-label.nav-search,
.inputText:-webkit-autofill:active~.floating-label.nav-search {
  top: -2px;
  bottom: 10px;
  left: 44px;
  font-size: 8px;
  opacity: 1;
}

.inputText:focus~.floating-label.nav-search .required,
.inputText:not([value=""])~.floating-label.nav-search .required,
.inputText:focus:required~.floating-label.nav-search,
.inputText:focus:optional~.floating-label.nav-search,
.inputText:-webkit-autofill~.floating-label.nav-search .required,
.inputText:-webkit-autofill:hover~.floating-label.nav-search .required,
.inputText:-webkit-autofill:focus~.floating-label.nav-search .required,
.inputText:-webkit-autofill:active~.floating-label.nav-search .required {
  font-size: 8px;
}

/* ::-webkit-scrollbar {
  display: none;
} */

/* For Webkit-based browsers (Chrome, Safari and Opera) */
.scrollbar-hide::-webkit-scrollbar {
  display: none;
}

/* For IE, Edge and Firefox */
.scrollbar-hide {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

/* For Chrome, Edge, and Safari */
/* .custom-scrollbar::-webkit-scrollbar {
  width: 10px; 
} */

/* .custom-scrollbar::-webkit-scrollbar-track {
  background: #f1f1f1; 
} */

/* .custom-scrollbar::-webkit-scrollbar-thumb {
  background-color: #888; 
  border-radius: 10px; 
  border: 2px solid #f1f1f1; 
} */

/* .custom-scrollbar::-webkit-scrollbar-thumb:hover {
  background: #555; 
} */

/* Firefox (custom scrollbars are supported via pseudo-elements) */
/* .custom-scrollbar {
  scrollbar-width: thin; 
  scrollbar-color: #888 #f1f1f1; 
} */


.custom-y-scrollbar {
  overflow-y: scroll; /* Forces scrollbar */
}

/* Custom scrollbar styling */
.custom-y-scrollbar::-webkit-scrollbar {
  width: 10px;
}

.custom-y-scrollbar::-webkit-scrollbar-track {
  background: #f1f1f1;
}

.custom-y-scrollbar::-webkit-scrollbar-thumb {
  background-color: #a0aec0; /* Gray color */
  border-radius: 10px;
  border: 2px solid #f1f1f1;
}

.custom-x-scrollbar {
  overflow-x: scroll; /* Forces scrollbar */
} 

.custom-x-scrollbar::-webkit-scrollbar {
  height: 10px;
}

.custom-x-scrollbar::-webkit-scrollbar-track {
  background: #f1f1f1;
}

.custom-x-scrollbar::-webkit-scrollbar-thumb {
  background-color: #a0aec0; /* Gray color */
  border-radius: 10px;
  border: 2px solid #f1f1f1;
}

.skeleton {
  position: relative;
  overflow: hidden;
  background-color: #DFE1E6;
  border-radius: 2px;
}

.skeleton::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  transform: translateX(-100%);
  background-image: linear-gradient(90deg,
      rgba(255, 255, 255, 0) 0,
      rgba(255, 255, 255, 0.2) 20%,
      rgba(255, 255, 255, 0.5) 60%,
      rgba(255, 255, 255, 0));
  animation: shimmer 1.5s infinite;
  content: "";
}

@keyframes shimmer {
  100% {
    transform: translateX(100%);
  }
}

.olLayerGooglePoweredBy {
  display: none
}

.gmnoprint a,
.gmnoprint span {
  display: none;
}

.gmnoprint div {
  background: none !important;
}

a[href^="http://maps.google.com/maps"] {
  display: none !important
}

a[href^="https://maps.google.com/maps"] {
  display: none !important
}

.gmnoprint a,
.gmnoprint span,
.gm-style-cc {
  display: none;
}

.gmnoprint div {
  background: none !important;
}

:root {
  --gm-button-background-color: white;

}

@media (prefers-color-scheme: dark) {
  :root {
    --gm-button-background-color: white;

  }
}

.gm-control-active {
  background-color: var(--gm-button-background-color) !important;
  border-radius: 5px !important;
  margin-bottom: 5px !important;
  color: #5452BF !important;
  height: 34px !important;
  width: 34px !important;
}

.gm-control-active>img {
  height: 10px !important;
  width: 10px !important;
  color: #5452BF !important;
}

.gmnoprint>div {
  box-shadow: none !important;
}

.gm-style {
  border-radius: 5px !important;
}

.map-design>div>div>div {
  border-radius: 5px !important;
}

.map-design-10>div>div>div {
  border-radius: 10px !important;
}

/* MentionField.css */

/* Remove border and outline */
.mention-field {
  border: none;
  outline: none;
}

/* Remove box-shadow on focus */
.mention-field:focus {
  box-shadow: none;
}

/* The slider */
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  /* background-color: #14283714; */
  background-color: #E2E8F0;
  -webkit-transition: .4s;
  transition: .4s;
}

.slider:before {
  position: absolute;
  content: "";
  /* height: 20px;
  width: 20px; */
  /* left: 2px;
  bottom: 2px; */
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
}

.toggleInput:checked+.slider {
  background-color: #22C55E;
}

.toggleInput:focus+.slider {
  box-shadow: 0 0 1px #22C55E;
}

.toggleInput:checked+.slider:before {
  -webkit-transform: translateX(14px);
  -ms-transform: translateX(14px);
  transform: translateX(14px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

.slide-in {
  transform: translateX(0);
}


.dropdown-container {
  position: relative;
  z-index: 1000;
}

.dropdown-items {
  position: absolute;
  top: 100%;
  left: 0;
  width: 200px;
  background-color: #fff;
  border: 1px solid #ccc;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  z-index: 1001;
}

.tox .tox-statusbar__path {
  display: none !important;
}

.flip {
  -webkit-transform: scaleY(-1);
  transform: scaleY(-1);
}
.gradient-border {
  border-image: linear-gradient(to top right, #62CC00 0%, #FFB902 100%);
  border-image-slice: 1;
  border-radius: 4px;
}

.custom-skeleton {
  background: linear-gradient(to right, #E2E8F0, #F1F5F9);
  animation: custom-skeleton-animation 3s ease-in-out infinite;
}

.epr-emoji-native {
  font-size: 18px !important;
}

.epr-main.EmojiPickerReact {
  /* height: 45px !important; */
  border: none !important;
}

.mention-input-container textarea{
  padding: 16px;
  outline: none;
  border: none;
  font-size: 12px;
  background-color: white;
  /* box-shadow: 0px 4px 16px 0px rgba(0,0,0,0.04); */
  overflow-y: auto;
  resize: none;
}

.mention-input-container textarea::placeholder {
  font-size: 12px;
  color: #d0d4d7
}

.mention-input-container__suggestions {
  top: 50% !important;
}

.mention-input-container__mentions__mention {
  color: #5452BF !important;
  background-color: transparent !important;
  text-decoration: none !important;
}

.mention-input-container__highlighter {
  display: none !important;
  color: #5452BF !important;
  
}
.mention-input-container__suggestions__item__display {
  color: #5452BF !important;
}

@keyframes custom-skeleton-animation {
  0% {
    background-position: -200px 0;
  }
  100% {
    background-position: 200px 0;
  }
}

.step-in-progress {
  /* font-size: 48px; */
  animation: spin 2s linear infinite;
}

@keyframes spin {
  from {
      transform: rotate(0deg);
  }
  to {
      transform: rotate(360deg);
  }
}

.slides {
    position: absolute;
    opacity: 0;
    transform: translateX(100%); 
    transition: opacity 0.5s ease, transform 0.5s ease;
    height: 700px;
}

.slides.active {
    opacity: 1; 
    transform: translateX(0%);
}

.slides.exit {
    opacity: 0; 
    transform: translateX(-100%); 
}


@media only screen and (min-width: 768px) {
  .sliderT {
    overflow: hidden; 
    white-space: nowrap; 
    width: 100%; 
}

.slider-track {
    animation: scroll 20s linear infinite; 
}
}


@keyframes scroll {
    0% {
        transform: translateX(0);
    }
    100% {
        transform: translateX(-50%); 
    }
}

/* Or use appearance to hide the default styles */
summary {
  list-style: none; /* Removes the bullet/arrow */
  appearance: none; /* For browsers supporting it */
  -webkit-appearance: none; /* Safari support */
  -moz-appearance: none; /* Firefox support */
}
