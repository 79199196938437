.rbc-calendar{
    font-family: "rocGrotesk-medium";
    font-size: 14px;
}
.rbc-month-header{
    background-color: #F8FAFC;
    color: #042821 ;
    border-color: #E2E8F0 !important;
}
.rbc-header{
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-color: #E2E8F0 !important;

}
rbc-header + .rbc-header {
    border-color: #E2E8F0 !important;
}
.rbc-month-view{
    border-color: #E2E8F0 !important;
}
.rbc-day-bg + .rbc-day-bg {
    /* border-color: #fff !important; */
}

/* .rbc-day-bg + .rbc-day-bg {
    border-color: #E2E8F0 !important;
} */ 
 /* for date and month */
.rbc-month-row + .rbc-month-row {
    border-color: #E2E8F0 !important;
}
.rbc-date-cell {
    padding-top: 8px;
    padding-right: 10px;
}
.rbc-off-range-bg {
    background: #fff;
}

.rbc-time-gutter .rbc-timeslot-group {
    border-bottom: none !important;

}

.rbc-timeslot-group{
    border-bottom: 1px solid #E2E8F0 ;
}

.rbc-day-slot .rbc-time-slot {
    border-top: none;
}
.rbc-time-header-content .rbc-all-day-cell  {
   /* background-color: #F8FAFC !important; */
}
/* .rbc-time-content .rbc-today {
    background-color: #fff;
} */
.rbc-today{
    background-color: #fff;
}
.rbc-time-content > .rbc-day-slot {
  
    background-color: white;
}
rbc-day-slot .rbc-event, .rbc-day-slot .rbc-background-event {
    border: none;
}

.rbc-label {
      background-color: #F8FAFC;

}
.rbc-time-content {
    border-top: 1px solid #E2E8F0 ;
}
.rbc-time-view {
    border:  1px solid #E2E8F0;
    border-radius: 0px 0px 8px 8px;
    
}


.rbc-date-cell.rbc-now.rbc-current button{
    background-color: rgba(255,185,2,0.2);
    border-radius: 4px;
    padding: 4px;
    margin-bottom: 2px;
    width: auto;
}


rbc-event.rbc-selected, .rbc-day-slot .rbc-selected.rbc-background-event {
    background-color: transparent;
}


.rbc-event.rbc-selected, .rbc-day-slot .rbc-selected.rbc-background-event {
    background-color: transparent;
}


.production  .rbc-event,.production .rbc-day-slot .rbc-background-event {
    box-sizing: border-box;
    box-shadow: none;
    margin: 0;
    padding: 2px 5px;
    margin: 2px;
    background-color: white;
    border-radius: 5px;
    color: #000;
    cursor: pointer;
    width: 100%;
    text-align: left;
    border-left: 4px solid #3B82F6 !important;
    font-size: 14px;
    border-top: 1px solid #E2E8F0 !important;
    border-bottom: 1px solid #E2E8F0 !important;
    border-right: 1px solid #E2E8F0 !important;

}
.production .rbc-month-row .rbc-event, .production .rbc-day-slot .rbc-background-event {
    box-sizing: border-box;
    box-shadow: none;
    margin: 0;
    padding: 2px 5px;
    margin: 2px;
    background-color: rgba(255,185,2,0.2) !important;
    border-radius: 5px;
    color: #000;
    cursor: pointer;
    width: 100%;
    text-align: left;
    font-size: 14px;
    border: none !important;
}

.tooltip {
  background-color: #fff;
  border: 1px solid #ccc;
  padding: 10px;
  border-radius: 5px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  max-width: 200px;
}

.marketing .rbc-event, .marketing .rbc-day-slot .rbc-background-event {
    /* border: none; */
    box-sizing: border-box;
    box-shadow: none;
    padding: 2px 5px;
    border-radius: 5px;
    color: #000;
    cursor: pointer;
    width: 100%;
    text-align: left;
    font-size: 14px;
    background-color: transparent !important;
    border-left: 0px !important;
    font-size: 14px;
    border-top: 0px !important;
    border-bottom: 0px !important;
    border-right: 0px !important;
    margin: 0px !important;
}
.marketing .rbc-month-row .rbc-event, .marketing .rbc-day-slot .rbc-background-event {
    /* border: none; */
    box-sizing: border-box;
    box-shadow: none;
    padding: 2px 5px;
    border-radius: 5px;
    color: #000;
    cursor: pointer;
    width: 100%;
    text-align: left;
    font-size: 14px;
    border: none !important;
    background-color: transparent !important;
    margin: 0px !important;
}
